import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { GrAdd } from "react-icons/gr";
import { MdDateRange, MdHourglassFull } from "react-icons/md";
import query_db from "./GetSpiders";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";

const gridStyle = { minHeight: 400 };

const defaultFilterValue = [
  { name: 'name', operator: 'contains', type: 'string', value: '' },
  { name: 'homepage', operator: 'contains', type: 'string', value: '' },
  { name: 'description', operator: 'contains', type: 'string', value: '' },
  { name: 'timezone', operator: 'contains', type: 'string', value: '' },
  { name: 'base_url', operator: 'contains', type: 'string', value: '' },
  { name: 'active', operator: 'eq', type: 'boolean', value: null },
  { name: 'errorCount', operator: 'gte', type: 'number', value: null }, // Changed to simple number field
];

const filterData = (data, filterValue) => {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.filter(item => {
    return filterValue.every(filter => {
      const { name, operator, type, value } = filter;
      if (value === null || value === '') return true;

      // Handle nested stats properties
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        const itemValue = item[parent]?.[child];
        // Important: Check if itemValue is explicitly 0
        if (itemValue === undefined || itemValue === null) return false;

        switch(operator) {
          case 'gte':
            return Number(itemValue) >= Number(value);
          default:
            return true;
        }
      }

      let itemValue = item[name];
      if (itemValue == null) return false;

      // Convert to string for comparison
      itemValue = itemValue.toString().toLowerCase();
      const searchValue = value.toString().toLowerCase();

      switch(operator) {
        case 'contains':
          return itemValue.includes(searchValue);
        case 'eq':
          return item[name] === value; // Keep boolean comparison as-is
        default:
          return true;
      }
    });
  });
};

const Spiders = () => {
  let history = useHistory();
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(null);
  
  // Fetch data only once when component mounts
  useEffect(() => {
    const fetchData = async () => {
      const result = await query_db();
      // Add errorCount field to each item
      const transformedResult = result.map(item => ({
        ...item,
        errorCount: item.stats?.Errors || 0
      }));
      setData(transformedResult);
      setRows(transformedResult); // Initial rows setup
    };
    fetchData();
  }, []); // Empty dependency array means this runs once on mount

  // Handle filtering separately
  useEffect(() => {
    if (data) {
      const filteredData = filterData(data, filterValue);
      setRows(filteredData);
    }
  }, [filterValue, data]); // Only run when filter or data changes

  const render = ({ value }) => {
    if (typeof value === "boolean") {
      value =
        value === true ? (
          <img
            alt=""
            width={20}
            height={20}
            src="https://upload.wikimedia.org/wikipedia/commons/3/31/Button_Icon_Green.svg"
          />
        ) : (
          <img
            alt=""
            width={20}
            height={20}
            src="https://upload.wikimedia.org/wikipedia/commons/2/27/Button_Icon_Black.svg"
          />
        );
      return value;
    } else if (typeof value === "object" && value.Start !== undefined) {
      const color = value.Errors > 0 ? "red" : "none";
        value = (
        <div>
          <p>
            <MdDateRange style={{ verticalAlign: "-10%" }} /> &nbsp;
            <b>{value.Start}</b> &nbsp;&nbsp;
            <MdHourglassFull style={{ verticalAlign: "-10%" }} /> <b>{Math.round(value.Elapsed)}</b> s
          </p>
          <p>
            Pages: <b>{value.Pages}</b>&nbsp;&nbsp; Items: <b>{value.Items}</b>&nbsp;&nbsp;
            <span style={{ color: color }}>Errors: <b>{value.Errors}</b> </span>
          </p>
        </div>
      );

      return value;
    }
    
    // Replace searchText highlighting with simple value return
    return value;
  };

  const initialColumns = [
    {
      name: "id",
      header: "ID",
      sortable: true,
      defaultFlex: 1,
      visible: false,
      render,
      filterEditor: 'string',
    },
    {
      name: "name",
      header: "Name",
      defaultFlex: 2,
      render,
      sortable: true,
      filterEditor: 'string',
    },
    {
      name: "homepage",
      header: "Homepage",
      defaultFlex: 1,
      render,
      sortable: true,
      filterEditor: 'string',
    },
    {
      name: "description",
      header: "Description",
      defaultFlex: 1,
      render,
      sortable: true,
      filterEditor: 'string',
    },
    {
      name: "timezone",
      header: "Timezone",
      defaultFlex: 0.8,
      render,
      sortable: true,
      filterEditor: 'string',
    },
    {
      name: "base_url",
      header: "Base URL",
      defaultFlex: 1.5,
      render,
      sortable: true,
      filterEditor: 'string',
    },
    {
      name: "stats",
      header: "Last Run Stats",
      defaultFlex: 1.5,
      render,
    },
    {
      name: "errorCount",
      header: "Errors",
      defaultFlex: 0.8,
      filterEditor: 'number',
      filterEditorProps: {
        placeholder: 'Errors ≥',
        type: 'number',
        min: 0
      },
      render: ({ value }) => value || 0,
    },
    {
      name: "active",
      header: "Active",
      defaultFlex: 1,
      textAlign: "center",
      render,
      sortable: true,
      filterEditor: 'boolean',
    },
  ];

  const [columns] = useState(initialColumns);

  const onCellClick = useCallback((event, cellProps) => {
    const { columnIndex, value } = cellProps;

    const redirectLink = async (c) => {
      if (c === 0 && data) {
        let index = data.findIndex(element => {
          if (element.name === value) {
            return true;
          }
          return false; // Add return false for array-callback-return
        });
        if (index !== -1) {
          window.location = "/spider/" + data[index].id;
        }
      }
    };
    redirectLink(columnIndex);
  }, [data]); // Add data to dependencies

  
  if (!AuthService.getCurrentUser()) {
    history.push("/login");
    window.location.reload();
  }
  return (
    <div>
      <h3>
        Spiders{" "}
        <img
          alt=""
          width="2%"
          style={{ filter: "brightness(0) invert(1)" }}
          src={"http://getdrawings.com/free-icon-bw/spider-icon-1.png"}
        />{" "}
      </h3>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <a href="/spider/new">
          <GrAdd style={{ margin: "5px", filter: "brightness(0) invert(1)" }} />
        </a>
      </div>
      <br />
      <br />

      <div id="growth">
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          columns={columns}
          dataSource={rows}
          editable={false}
          rowHeight={null}
          onCellClick={onCellClick}
          rtl={false}
          defaultFilterValue={filterValue}
          onFilterValueChange={setFilterValue}
          filterable={true}
          sortable={true}
        />
      </div>
    </div>
  );
};

export default Spiders;
